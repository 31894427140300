
  import Vue from 'vue';

  export default Vue.extend({
    name: 'es-banners-as-footer-mobile',

    props: {
      mobileBanners: {
        default: Array,
        type: Array,
      },
    },
  });
